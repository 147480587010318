// 项目详情页
<template>
  <div class="sld_project_detail">
    <!-- <MemberTitle :memberTitle="L['项目详情']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["项目详情"] }}</h3>

      <div class="block5 flex-col" v-if="projectInfo.data">
        <div class="section2 flex-row">
          <span class="info13">{{ projectInfo.data.projectName }}</span>
        </div>
        <div class="section3 flex-row">
          <span class="info14"> 创建时间：{{ projectInfo.data.createTime }}</span>
          <span class="word14">
            项目周期：
            {{ projectInfo.data.startTime ? projectInfo.data.startTime.split(' ')[0] : '' }}
            ～
            {{ projectInfo.data.endTime ? projectInfo.data.endTime.split(' ')[0] : '' }}
          </span>
        </div>
        <div class="section4 flex-row">
          <span class="word15">{{ projectInfo.data.description }}</span>
        </div>
      </div>

      <!-- 顶部标题 start-->
      <div class="cart_title flex_row_start_center">
        <div class="cart_title_pre flex_row_start_center">
          <el-checkbox v-model="allCheck" @change="changeAll"></el-checkbox>
          <span style="margin-left: 18px">图片</span>
        </div>
        <div class="cart_title_pre">
          <span>产品信息</span>
        </div>
        <div class="cart_title_pre">
          <span>规格</span>
        </div>
        <div class="cart_title_pre">
          <span>材质</span>
        </div>
        <div class="cart_title_pre">
          <span>产地</span>
        </div>
        <div class="cart_title_pre">
          <span>单价</span>
        </div>
        <div class="cart_title_pre">
          <span>货物所在地</span>
        </div>
      </div>
      <!-- 顶部标题 end-->

      <!-- 列表 start -->
      <div class="cart_lists" v-if="projectInfo.data.productList && projectInfo.data.productList.length > 0">
        <div class="cart_lists_pre" v-for="(item, index) in projectInfo.data.productList" :key="index">
          <div class="goods_list flex-row" v-if="item.isSell">

            <!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
            <div class="goods_pre_sel">
              <el-checkbox v-model="item.ischeck" @change="changeSelect(item, $event)"></el-checkbox>
            </div>
            <div class="section8">
              <div class="section8_img" :style="`background-image:url(${item.goodsImage})`"
                @click="goodsDetail(item.productId)"></div>
            </div>
            <div class="section8 flex-col">
              <div class="txt19 txt191" @click="goodsDetail(item.productId)">{{ item.goodsName }}</div>
              <div class="txt19 txt191" @click="goodsDetail(item.productId)" style="margin-top:10px;">
                <p style="margin-bottom: 5px;margin-top: 10px;">可供重量：{{ item.goodsProperty.goodsStockWeight }}吨</p>
                <p>可供数量：{{ item.goodsProperty.goodsStockNum }}</p>
              </div>
              
              <!-- <div class="txt19 txt191" @click="goodsDetail(item.productId)" v-if="item.orderCode">
                订货编码：{{ item.orderCode }}</div> -->

            </div>
            <div class="section8 txt19_box_o flex-col">
              <div class="txt19">{{ item.goodsProperty.specModel }}</div>
            </div>
            <div class="section8 flex-col">
              <span class="txt20">{{ item.goodsProperty.material }}</span>
            </div>
            <div class="section8 flex-col">
              <span class="txt_price">{{ item.goodsProperty.originPlace }}</span>
            </div>
            <div class="section8 flex-col">
              <span class="txt_price">￥{{ item.goodsProperty.goodsPrice.toFixed(2) || '--' }}</span>
            </div>
            <div class="section8 flex-col">
              <span class="txt_price">{{ item.goodsProperty.presencePlace==1?'沈阳':'鞍山' }}</span>
            </div>
          </div>
          <div class="goods_cao" v-if="item.isSell">
              <div class="goods_cao_o flex_row_end_start" >
                <div class="goods_company_box goods_box_com" >
                    <p>销售单位：</p>
                    <div class="flex_row_start_center">
                      <div class="goods_company_radio flex_row_start_center" v-if="item.goodsProperty.meterWeight" @click="goJiaType(index,1)">
                        <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==1">
                        <img src="@/assets/radio_un.png" alt="" v-else>
                          <span>米</span>
                      </div>
                      <div class="goods_company_radio flex_row_start_center" v-if="item.goodsProperty.isSupportKg==1" @click="goJiaType(index,4)">
                          <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==4">
                          <img src="@/assets/radio_un.png" alt="" v-else>
                          <span>吨</span>
                      </div>
                      <div class="goods_company_radio flex_row_start_center" v-if="item.goodsProperty.roll&&item.goodsProperty.roll.length>0" @click="goJiaType(index,5)">
                          <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==5">
                          <img src="@/assets/radio_un.png" alt="" v-else>
                          <span>卷</span>
                      </div>
                      <div class="goods_company_radio flex_row_start_center" v-if="item.goodsProperty.sliceWeight" @click="goJiaType(index,2)">
                          <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==2">
                          <img src="@/assets/radio_un.png" alt="" v-else>
                          <span>片</span>
                      </div>
                      <div class="goods_company_radio flex_row_start_center" v-if="item.goodsProperty.rootWeight" @click="goJiaType(index,3)">
                          <img src="@/assets/radio_xuan.png" alt="" v-if="item.types==3">
                          <img src="@/assets/radio_un.png" alt="" v-else>
                          <span>根</span>
                      </div>
                    </div>
                </div>
                <div class="goods_longitude_box goods_box_com" v-if="item.types==1">
                  <p>所需长度：</p>
                  <div class="longitude_inp flex_row_start_center">
                      <input type="number" placeholder="请输入长度" v-model="item.chang" @input="numFour(index,'chang',item.types)">
                      <span>米
                         <!-- = {{item.goodsProperty.meterWeight}}吨 -->
                        </span>
                  </div>
                </div>
                <div class="goods_longitude_box goods_box_com" v-if="item.types==4">
                  <p>所需吨数：</p>
                  <div class="longitude_inp flex_row_start_center">
                      <input type="number" placeholder="请输入吨数" v-model="item.dun" @input="numFour(index,'dun',item.types)">
                      <span>吨</span>
                  </div>
                </div>
                <div class="goods_longitude_box goods_box_com" v-if="item.types==5">
                  <p>卷重：</p>
                  <div class="longitude_xuan flex_row_start_start">
                      <div class="longitude_xuan_o flex_row_center_center" :class="{'longitude_xuan_check':it.rollWight==item.rollWight}" v-for="(it,ind) in item.goodsProperty.roll" @click="goJiaTypejuan(index,5,it.rollWight)" :key="ind">
                      {{it.rollWight}}吨
                      </div>
                  </div>
                </div>
                <div class="goods_longitude_box goods_box_com" v-if="item.types==2">
                  <p>所需片数：</p>
                  <div class="longitude_inp flex_row_start_center">
                      <input type="number" placeholder="请输入片数" v-model="item.pian" @input="numFour(index,'pian',item.types)">
                      <span>片
                         <!-- =  {{item.goodsProperty.sliceWeight}}吨 -->
                        </span>
                  </div>
                </div>
                <div class="goods_longitude_box goods_box_com" v-if="item.types==3">
                  <p>所需根数：</p>
                  <div class="longitude_inp flex_row_start_center">
                      <input type="number" placeholder="请输入根数" v-model="item.gen" @input="numFour(index,'gen',item.types)">
                      <span>根
                         <!-- =  {{item.goodsProperty.rootWeight}}吨 -->
                        </span>
                  </div>
                </div>
                <div class="goods_mode_box goods_box_com" v-if="item.goodsProperty.deliverType==1">
                    <p>交货方式：</p>
                    <div class="mode_type flex_row_start_center">
                      <div class="mode_type_box flex_row_start_center" v-if="item.goodsProperty.deliverType==1">
                          <img src="@/assets/cart/sel.png" alt=""  v-if="item.deliverType==1" @click="editNums(index,item.types,2)"/>
                          <img src="@/assets/cart/no_sel.png" alt="" v-else  @click="editNums(index,item.types,1)"/>
                          <span>净板交货 {{ item.goodsProperty.deliverTypePrice }}元/吨</span>
                      </div>
                      <div class="mode_type_box flex_row_start_center"  v-if="item.goodsProperty.deliverType==2">

                          <span>带头尾交货</span>
                      </div>
                    </div>
                </div>
              </div>
              <div class="goods_cao_t flex_row_start_center">
                <div class="goods_cao_t_box">
                    <div class="goods_cao_t_1 flex_row_start_center">
                      <p>合计重量：</p>
                      <span>{{item.weightSum?item.weightSum.toFixed(3):0}}吨</span>
                    </div>
                </div>
                <div class="goods_cao_t_box flex_column_start_center">
                    <div class="goods_cao_t_2">合计单价：<span>￥{{item.singlePrice?item.singlePrice.toFixed(2):0}}/吨</span></div>
                    <div class="goods_cao_t_3">合计单价=产品单价+增值服务单价</div>
                </div>
                <div class="goods_cao_t_box">
                    <div class="goods_cao_t_box_o flex_column_start_end">
                      <div class="goods_cao_t_2">合计金额：<span>￥{{item.sumPrice?item.sumPrice.toFixed(2):0}}</span></div>
                      <div class="goods_cao_t_3">不含物流运费</div>      
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div class="cart_lists_pre" v-for="(item, index) in projectInfo.data.invalidList" :key="index">
          <!-- <div class="invalid_goods_title">{{ L["失效产品"] }}</div> -->
          <div class="goods_list flex-row gray">
            <!-- 可选择 1-正常状态，2-商品失效（已删除），3-商品无货-->
            <div class="goods_pre_sel unuse">
              <span>失效</span>
            </div>
            <div class="section8">
              <div class="section8_img" :style="`background-image:url(${item.goodsImage})`"
                @click="goodsDetail(item.productId)"></div>
            </div>
            <div class="section8 flex-col"><span class="txt19 txt191"
                @click="goodsDetail(item.productId)">{{ item.goodsSpec }}</span></div>
            <div class="section8 txt19_box flex-col">
              <el-scrollbar>
                <div class="flex-col">
                  <span class="txt19" v-for="items in item.attributeList" :key="items">
                    {{ items.attributeName }}：{{ items.attributeValue }}
                  </span>
                  <span v-show="item.attributeList.length == 0" style="text-indent:20px;line-height:92px;">--</span>
                </div>
              </el-scrollbar>
            </div>
            <div class="section8 flex-col">
              <span class="txt20">{{ item.produceStateValue }}</span>
              <span class="info23">发货日：{{ item.deliverTime }}天</span>
            </div>
            <div class="section8 flex-col">
              <span class="txt20 txt_price" v-if="item.goodsPrice">￥{{ item.goodsPrice.toFixed(2) }}</span>
              <div style="width: 130px;" class="flex_row_center_center" v-else>
                <div class=" type2"></div>
              </div>

            </div>
            <div class="section9 flex-col">
              <div class="goods_edit_nem flex_row_center_center">
                <span class="disabled">-</span>
                <input disabled type="number" v-model="item.goodsNum" @input="editNum('edit', item, $event)"
                  @blur="editNum('blur', item, $event)" />
                <span class="disabled">+</span>
                <span class="goods_edit_nem_after">{{ item.saleUnit }}</span>
              </div>
              <div class="section9_desc">
                <span>起订量: {{ item.minBuyNum }}</span>
                <span class="desc">{{ item.saleUnitDes }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SldCommonEmpty v-else tip="暂无产品数据" totalWidth="934px" />
      <!-- 列表 end -->
    </div>
    <!-- 操作按钮 start -->
    <div class="options_btn flex_row_between_center" ref="optionsBtn"
      v-if="projectInfo.data.productList && projectInfo.data.productList.length > 0">
      <div class="options_btn_left flex_row_start_center">
        <div class="options_sel">
          <el-checkbox v-model="allCheck" @change="changeAll"></el-checkbox>
          <!-- <img src="@/assets/cart/sel.png" alt="" class="store_sel"/> -->
          <!-- <img src="@/assets/cart/no_sel.png" alt="" class="store_sel"/> -->
          <span>{{ L["全选"] }}</span>
        </div>
        <div class="del_all cursor_pointer" @click="outPut">
          导出已选产品
        </div>
        <div class="del_all cursor_pointer" @click="delProduct(1)"
          v-if="projectInfo.data.productList && projectInfo.data.productList.length > 0">
          删除已选产品
        </div>
        <div class="del_all cursor_pointer" @click="delProduct(2)"
          v-if="projectInfo.data.invalidList && projectInfo.data.invalidList.length > 0">
          清空无效产品
        </div>
      </div>
      <div class="options_sel_num" style="font-size: 14px;">
          {{ L["已选择"] }}{{ selectNum }}{{ L["件产品"] }}
        </div>
      <div class="options_right flex_row_start_center">
        <div style="margin-right: 10px;">
          <div class="options_sel_num">
            合计总吨数：
            <span>{{ projectInfo.data.sumKg?projectInfo.data.sumKg.toFixed(3):0 }}吨</span>
          </div>
          <div class="options_sel_price flex-row">
            <div class="options_all_count flex_column_start_end">
              <span>合计金额：</span>
            </div>
            <div class="options_promotion flex_column_start_end">
              <span>￥{{ projectInfo.data.totalAmount?projectInfo.data.totalAmount.toFixed(2):0 }}</span>
            </div>
          </div>
        </div>
        <div class="go_buy cursor_pointer" :class="{ active: selectNum > 0 }" @click="confirmOrder"
          v-if="memberInfo.memberType == 2">
          {{ L["提交需求"] }}
        </div>
        <div class="go_buy cursor_pointer" :class="{ active: selectNum > 0 }" @click="goBuy">
          {{ L["立即购买"] }}
        </div>
      </div>
    </div>
    <!-- 操作按钮 end -->
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import SldCommonEmpty from '@/components/SldCommonEmpty';
import { useRoute, useRouter } from "vue-router";
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  name: "project-detail",
  components: {
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const memberInfo = store.state.memberInfo;
    const projectId = ref(route.query.id);
    const isEdit = ref(route.query.edit ? true : false);
    const projectInfo = reactive({ data: {} });
    const allCheck = ref(false);
    const productInfos = ref('');
    const selectNum = ref(0);
    const totalMoney = ref(0);
    const isPaying = ref(false);
    const goodsInfos = ref('');
    const invalidIds = ref('');
    const isShowTotal = ref(true)
    const getInfo = () => {
      let param = {
        projectId: projectId.value
      }
      proxy.$get('v3/member/front/project/detail', param)
        .then(res => {
          if (res.state == 200) {
            let ids = [];
            res.data.productList = []; //产品列表
            res.data.invalidList = []; //失效列表
            if (res.data.projectGoodsList.length > 0) {
              res.data.projectGoodsList.map(item => {
                if (item.state != 3) {
                  // res.data.invalidList.push(item);
                  // ids.push(item.bindId);
                } else {
                  item.ischeck = false;
                  if(!item.type){
                    if(item.goodsProperty.meterWeight){
                      item.types = 1
                      item.chang = ''
                    }else if(item.goodsProperty.isSupportKg==1){
                      item.types = 4
                        item.dun = ''
                      }else if(item.goodsProperty.roll.length>0){
                        item.types = 5
                    }else if(item.goodsProperty.sliceWeight){
                        item.types = 2
                        item.pian = ''
                      }else if(item.goodsProperty.rootWeight){
                        item.types = 3
                        item.gen = ''
                      }
                      item.singlePrice = item.deliverType==1?item.goodsProperty.deliverTypePrice+item.goodsProperty.goodsPrice:item.goodsProperty.goodsPrice
                      item.weightSum = 0
                      item.sumPrice = 0
                  }
                  res.data.productList.push(item);
                }
              })
              invalidIds.value = ids.join(',');
            }
            res.data.sumKg = 0
            res.data.totalAmount = 0
            projectInfo.data = res.data;
          }
        })
    }

    //商品数量的编辑
    const editNum = (type, item, e) => {
      let currentSpecNum = item.goodsNum;
      let minBuyNum = item.minBuyNum ? item.minBuyNum : 1;

      let maxMin = Math.min(99999, item.productStock)


      if (type == "add") {
        currentSpecNum++;
        if (currentSpecNum > maxMin) {
          currentSpecNum = maxMin;
          ElMessage.warning(L["超过购买限制!"]);
        }
      } else if (type == "edit") {
        currentSpecNum = e.currentTarget.value.replace(/\D/g, "");
        if (currentSpecNum > maxMin) {
          ElMessage.warning(L["超过购买限制!"]);
          currentSpecNum = maxMin;
        }
      } else if (type == 'blur') {
        currentSpecNum = e.currentTarget.value;
        if (currentSpecNum <= minBuyNum) {
          currentSpecNum = minBuyNum;
          ElMessage.warning('最低限购' + minBuyNum + '件');
        } else {
          currentSpecNum = currentSpecNum.replace(/\D/g, "");
          if (currentSpecNum > maxMin) {
            ElMessage.warning(L["超过购买限制!"]);
            currentSpecNum = maxMin;
          }
        }
      } else if (type == "reduce") {
        if (currentSpecNum > minBuyNum) {
          currentSpecNum--;
        } else {
          currentSpecNum = minBuyNum;
          ElMessage.warning('最低限购' + minBuyNum + '件');
        }
      }
      item.goodsNum = currentSpecNum
      editGoodsNum(item);
    };

    const editGoodsNum = (item) => { //编辑商品数量
      let param = {
        bindId: item.bindId,
        num: item.goodsNum,
        productId: item.productId
      }
      proxy.$post('v3/member/front/project/editGoodsNum', param)
        .then(res => {
          if (res.state == 200) {
            checkSelect();
          } else {
            ElMessage.error(res.msg)
          }
        })
    };

    const changeAll = () => { //全选切换
      checkSelect(allCheck.value ? 1 : 2);
    }
    const changeSelect = (item, e) => { //商品选择切换
      if (item.goodsNum > item.productStock) {
        ElMessage('库存不足')
        item.ischeck = false
        return
      }
      checkSelect();
    }
    const checkSelect = (type) => { //筛选选中的商品 type: 1 全选 2 全不选
      let arr = [];
      let num = 0;
      let useNum = 0;
      let total = 0;
      let goods = [];
      let priceList = []
      projectInfo.data.productList.forEach(item => {
        useNum++;
        if (type == 1) {
          item.ischeck = true;
          num++;
          total += item.goodsPrice * item.goodsNum
          arr.push(item.productId + '-' + item.goodsNum);
          goods.push(item.goodsId);
          priceList.push(item.goodsPrice)
        } else if (type == 2) {
          item.ischeck = false;
        } else if (item.ischeck) {
          num++;
          total += item.goodsPrice * item.goodsNum
          arr.push(item.productId + '-' + item.goodsNum);
          // goods.push(item.productId + '-' + item.goodsNum);
          goods.push(item.goodsId);
          priceList.push(item.goodsPrice)
        }


      })

      isShowTotal.value = priceList.every(i => i > 0)


      if (!type) {
        allCheck.value = (num == useNum ? true : false)
      }
      selectNum.value = num;
      totalMoney.value = total == 0 ? 0 : total.toFixed(2);
      productInfos.value = arr.length > 0 ? arr.join(',') : '';

      goodsInfos.value = goods;
      summation()
    }

    const confirmOrder = () => { //提交清单
      if (selectNum.value == 0) {
        return;
      } else if (isPaying.value) {
        return;
      }
      isPaying.value = true;
      let param = {
        productInfos: productInfos.value,
        projectId: projectId.value
      }
      proxy.$post('v3/member/front/member/purchase/apply/submit', param)
        .then(res => {
          if (res.state === 200) {
            ElMessage.success(res.msg);
            setTimeout(() => {
              isPaying.value = false;
              if (window.history.state.back) {
                router.back();
              } else {
                router.replace({ path: '/member/project/list' })
              }
            }, 1000)
          } else {
            isPaying.value = false;
            ElMessage.error(res.msg);
          }
        })
    };

    const goBuy = () => { //立即购买
      if (selectNum.value == 0) {
        return;
      }
      let products = []
      for(let i=0;i<projectInfo.data.productList.length;i++){
        let item = projectInfo.data.productList[i]
        if(item.ischeck){
          // goodsId-type-deliverType-num-rollWight
          if(item.types==1){
            if(!item.chang){
              ElMessage.error('请输入产品'+item.goodsName+'的长度')
              return
            }else{
              let product = `${item.goodsId}-${item.types}-${item.deliverType}-${item.chang}`
              products.push(product)
            }
          }else if(item.types==2){
            if(!item.pian){
              ElMessage.error('请输入产品'+item.goodsName+'的片数')
              return
            }else{
              let product = `${item.goodsId}-${item.types}-${item.deliverType}-${item.pian}`
              products.push(product)
            }
          }else if(item.types==3){
            if(!item.gen){
              ElMessage.error('请输入产品'+item.goodsName+'的根数')
              return
            }else{
              let product = `${item.goodsId}-${item.types}-${item.deliverType}-${item.gen}`
              products.push(product)
            }
          }else if(item.types==4){
            if(!item.dun){
              ElMessage.error('请输入产品'+item.goodsName+'的吨数')
              return
            }else{
              let product = `${item.goodsId}-${item.types}-${item.deliverType}-${item.dun}`
              products.push(product)
            }
          }else if(item.types==5){
            if(!item.rollWight){
              ElMessage.error('请选择产品'+item.goodsName+'的卷重')
              return
            }else{
              for(let i=0;i<item.goodsProperty.roll.length;i++){
                  let item = item.goodsProperty.roll[i]
                  if(item.rollNum==0){
                    ElMessage('产品'+item.goodsName+'卷重为'+item.rollWight+'吨'+'的卷库存不足，请重新选择')
                    return
                  }
              }
              let product = `${item.goodsId}-${item.types}-${item.deliverType}-${1}-${item.rollWight}`
              products.push(product)
            }
          }
        }
      }
      let query = {
        productSets: encodeURIComponent(products.join(',')),
        projectId:projectId.value,
        ifcart:false
      }
      router.push({
        path: "/buy/confirm",
        query
      })
      // proxy.$post("v3/business/front/orderOperate/confirm", {
      //   products: productInfos.value
      // }).then(res => {
      //   if (res.state == 200) {
      //     router.push({
      //       path: "/buy/confirm",
      //       query
      //     })
      //   } else {
      //     ElMessage(res.msg)
      //   }
      // })




    };

    const isOutputing = ref(false); //是否点击导出产品
    const outPut = () => { //导出已选产品
      if (goodsInfos.value.length == 0) {
        ElMessage('请选择要导出的产品');
      } else {
        if (isOutputing.value) return;
        isOutputing.value = true;
        let param = {
          goodsInfos: goodsInfos.value.join(',')
        }
        proxy
          .$get('v3/member/front/project/export', param)
          .then(res => {
            if(res.state==200){
              // res.msg
              ElMessage.success('导出成功');
              window.open(res.msg, '_blank')
            }else{
              ElMessage.error(res.msg)
            }
            // let tmp = new Blob([res], { type: 'application/vnd.ms-excel' })
            // const aLink = document.createElement('a');
            // document.body.appendChild(aLink);
            // aLink.style.display = 'none';
            // aLink.href = window.URL.createObjectURL(tmp);
            // aLink.setAttribute('download', projectInfo.data.projectName);
            // aLink.click();
            // document.body.removeChild(aLink);
            // ElMessage.success('导出成功');
            // isOutputing.value = false;
          })
          .catch(() => {
            ElMessage.error('导出失败')
            isOutputing.value = false;
          })
      }
    };

    const delProduct = (type) => {
      let title = ''
      let param = {};

      if (type == 1) {
        title = '确定删除选中产品?';

        let arr = [];
        projectInfo.data.productList.forEach(item => {
          if (item.ischeck) {
            arr.push(item.bindId);
          }
        })
        if (arr.length == 0) {
          ElMessage.warning('请选择要删除的产品');
          return;
        }
        param.bindIds = arr.join(',');

      } else {
        title = '确定清空无效产品?';
        param.bindIds = invalidIds.value;
      }

      ElMessageBox.confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        proxy.$post('v3/member/front/project/delGoods', param).then(res => {
          if (res.state == 200) {
            ElMessage.success(res.msg);
            if (type == 1) {
              projectInfo.data.productList = projectInfo.data.productList.filter(item => {
                return (param.bindIds.indexOf(item.bindId) == -1);
              })
              allCheck.value = false;
              changeSelect();
            } else {
              projectInfo.data.invalidList = [];
              invalidIds.value = '';
            }
          } else {
            ElMessage.error(res.msg);
          }
        })
      })

    };

    const goodsDetail = (productId) => {
      router.push({
        path: '/goods/detail',
        query: { productId }
      })
    };

    //计算
    const calculations = (index,type)=>{
      if(type==1){//米
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].chang
          let inpValue = projectInfo.data.productList[index].chang
          if(inpValue){
            projectInfo.data.productList[index].weightSum = inpValue*projectInfo.data.productList[index].goodsProperty.meterWeight
            projectInfo.data.productList[index].sumPrice = projectInfo.data.productList[index].weightSum*projectInfo.data.productList[index].singlePrice
          }else{
            projectInfo.data.productList[index].weightSum = 0
            projectInfo.data.productList[index].sumPrice = 0
          }
        }else if(type==4){//吨
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].dun
          let inpValue = projectInfo.data.productList[index].dun
          if(inpValue){
            projectInfo.data.productList[index].weightSum = inpValue
            projectInfo.data.productList[index].sumPrice = projectInfo.data.productList[index].weightSum*projectInfo.data.productList[index].singlePrice
          }else{
            projectInfo.data.productList[index].weightSum = 0
            projectInfo.data.productList[index].sumPrice = 0
          }
        }else if(type==5){//卷
          let inpValue = projectInfo.data.productList[index].rollWight
          if(inpValue){
            projectInfo.data.productList[index].weightSum = inpValue
            projectInfo.data.productList[index].sumPrice = projectInfo.data.productList[index].weightSum*projectInfo.data.productList[index].singlePrice
          }else{
            projectInfo.data.productList[index].weightSum = 0
            projectInfo.data.productList[index].sumPrice = 0
          }
        }else if(type==2){//片
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].pian
          let inpValue = projectInfo.data.productList[index].pian
          if(inpValue){
            projectInfo.data.productList[index].weightSum = inpValue*projectInfo.data.productList[index].goodsProperty.sliceWeight
            projectInfo.data.productList[index].sumPrice = projectInfo.data.productList[index].weightSum*projectInfo.data.productList[index].singlePrice
          }else{
            projectInfo.data.productList[index].weightSum = 0
            projectInfo.data.productList[index].sumPrice = 0
          }
        }else if(type==3){//根
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].gen
          let inpValue = projectInfo.data.productList[index].gen
          if(inpValue){
            projectInfo.data.productList[index].weightSum = inpValue*projectInfo.data.productList[index].goodsProperty.rootWeight
            projectInfo.data.productList[index].sumPrice = projectInfo.data.productList[index].weightSum*projectInfo.data.productList[index].singlePrice
          }else{
            projectInfo.data.productList[index].weightSum = 0
            projectInfo.data.productList[index].sumPrice = 0
          }
        }
        summation()
    }

    // 计算合计吨和金额
    const summation = ()=>{
      let gross_ton = 0//合计总吨数
        let totals = 0//合计金额
        projectInfo.data.productList.forEach(item=>{
          if(item.ischeck){
            gross_ton+=Number(item.weightSum)
            totals+=Number(item.sumPrice)
          }
        })
        projectInfo.data.sumKg = gross_ton
        projectInfo.data.totalAmount = totals
    }

    // 切换销售单位
    const goJiaType =(index,type)=>{
      if(projectInfo.data.productList[index].types!=projectInfo.data.productList[index].type){
        projectInfo.data.productList[index].types = type
        calculations(index,type)
      }
    }

    // 选卷的时候
    const goJiaTypejuan = (index,type,rollWight)=>{
      projectInfo.data.productList[index].rollWight = rollWight
      calculations(index,type)
    }

    // 输入框
    const numFour =(index,type,types)=>{
      // 米
      if(type=='chang'){
        if(projectInfo.data.productList[index].chang<0){
          projectInfo.data.productList[index].chang=0
          projectInfo.data.productList[index].buyNumType = 0
        }
        let num = String(projectInfo.data.productList[index].chang).split('.')
        if(num[1]&&num[1].length>3){
          num[1] = num[1].substring(0,3)
          let nums = num.join('.')
          projectInfo.data.productList[index].chang = Number(nums)
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].chang
        }
        projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].chang
        calculations(index,types)
      }else if(type=='dun'){//吨
        if(projectInfo.data.productList[index].dun<0){
          projectInfo.data.productList[index].dun=0
          projectInfo.data.productList[index].buyNumType = 0
        }
        let num = String(projectInfo.data.productList[index].dun).split('.')
        if(num[1]&&num[1].length>3){
          num[1] = num[1].substring(0,3)
          let nums = num.join('.')
          projectInfo.data.productList[index].dun = Number(nums)
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].dun
        }
        projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].dun
        calculations(index,types)
      }else if(type=='pian'){//片
        if(projectInfo.data.productList[index].pian<1){
          projectInfo.data.productList[index].pian=''
          projectInfo.data.productList[index].buyNumType = ''
        }
        let num = String(projectInfo.data.productList[index].pian).split('.')
        if(num[0].length>0){
          projectInfo.data.productList[index].pian = Number(num[0])
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].pian
        }
        projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].pian
        calculations(index,types)
      }else if(type=='gen'){//根
        if(projectInfo.data.productList[index].gen<1){
          projectInfo.data.productList[index].gen=''
          projectInfo.data.productList[index].buyNumType = ''
        }
        let num = String(projectInfo.data.productList[index].gen).split('.')
        if(num[0].length>0){
          projectInfo.data.productList[index].gen = Number(num[0])
          projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].gen
        }
        projectInfo.data.productList[index].buyNumType = projectInfo.data.productList[index].gen
        calculations(index,types)
      }
    }
    const editNums = (index,type,num)=>{
      projectInfo.data.productList[index].deliverType = num
      projectInfo.data.productList[index].singlePrice = num==1?projectInfo.data.productList[index].goodsProperty.deliverTypePrice+projectInfo.data.productList[index].goodsProperty.goodsPrice:projectInfo.data.productList[index].goodsProperty.goodsPrice
      calculations(index,type)
    }


    onMounted(() => {
      getInfo();
    })

    return {
      L,
      memberInfo,
      projectInfo,
      isEdit,
      projectId,
      getInfo,
      allCheck,
      productInfos,
      editNum,
      editGoodsNum,
      changeAll,
      changeSelect,
      confirmOrder,
      goBuy,
      checkSelect,
      selectNum,
      totalMoney,
      isPaying,
      outPut,
      isOutputing,
      goodsInfos,
      delProduct,
      invalidIds,
      goodsDetail,
      isShowTotal,
      goJiaType,
      goJiaTypejuan,
      numFour,
      calculations,
      editNums,
      summation
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../style/orderProjectDetail.scss";
</style>
<style>
.bd5 .el-table__header,
.bd5 .el-table__body {
  width: 100% !important;
}
input::-webkit-inner-spin-button{ 
    -webkit-appearance: none !important;    
    margin: 0;    
    -moz-appearance: textfield; 
}
</style>